import React from "react";
import logo from "../../assets/images/branding/k-logo-t.png";
import SocialMediaLinks from "./SocialMediaLinks";
import ContactInformation from "./ContactInformation";

function Header() {
  return (
    <div className="head py-2">
      <div className="d-flex justify-content-between align-items-center px-3 flex-wrap">
        <SocialMediaLinks />
        <div className="order-1 order-md-2 mb-2 mb-md-0">
          <img
            src={logo}
            className="img-fluid mh-50 d-block mx-auto"
            alt="Logo"
          />
        </div>
        <ContactInformation />
      </div>
    </div>
  );
}

export default Header;
