import React, { Suspense, lazy } from "react";

const Landing = lazy(() => import("./sections/Landing"));
const About = lazy(() => import("./sections/About"));
const Guesthouses = lazy(() => import("./sections/Guesthouses"));
const Amenities = lazy(() => import("./sections/Amenities"));
const Review = lazy(() => import("./sections/Review"));
const Maps = lazy(() => import("./sections/Maps"));

function Home() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Landing />
      <About />
      <Guesthouses />
      <Amenities />
      <Maps />
      <Review />
    </Suspense>
  );
}

export default Home;
