import "../../assets/styles/components/nav.css";
import React from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

import Header from "./Header";

function Navbar({ setSection }) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        setSection(sectionId);
      }, 100); // Delay to ensure navigation completes
    } else {
      setSection(sectionId);
    }
  };

  const scrollLinks = [
    { to: "/", label: "Home" },
    { to: "about", label: "About" },
    { to: "guesthouses", label: "Guesthouses" },
    { to: "amenities", label: "Amenities" },
  ];

  const reviewsLink = { to: "reviews", label: "Reviews" };

  const routerLinks = [
    { to: "/Attractions", label: "Attractions" },
    { to: "/Facilities", label: "Facilities" },
    { to: "/Gallery", label: "Gallery" },
  ];

  const renderLinks = () =>
    location.pathname === "/"
      ? scrollLinks.map(({ to, label }) => (
          <li key={to} className="nav-item">
            <ScrollLink to={to} smooth={true} className="nav-link">
              {label}
            </ScrollLink>
          </li>
        ))
      : scrollLinks.map(({ to, label }) => (
          <li key={to} className="nav-item">
            <RouterLink
              to="/"
              onClick={() => handleNavigation(to)}
              className="nav-link"
            >
              {label}
            </RouterLink>
          </li>
        ));

  return (
    <div>
      <Header></Header>
      <nav className="navbar navbar-expand-lg navbar-light bg-light rounded">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse w-100" id="navbarNav">
            <ul className="navbar-nav mx-auto justify-content-center">
              {renderLinks()}
              {routerLinks.map(({ to, label }) => (
                <li key={to} className="nav-item">
                  <RouterLink to={to} className="nav-link">
                    {label}
                  </RouterLink>
                </li>
              ))}
              {location.pathname === "/" ? (
                <li className="nav-item">
                  <ScrollLink
                    to={reviewsLink.to}
                    smooth={true}
                    className="nav-link"
                  >
                    {reviewsLink.label}
                  </ScrollLink>
                </li>
              ) : (
                <li className="nav-item">
                  <RouterLink
                    to="/"
                    onClick={() => handleNavigation(reviewsLink.to)}
                    className="nav-link"
                  >
                    {reviewsLink.label}
                  </RouterLink>
                </li>
              )}
              <BookNowButton />
            </ul>
            
          </div>
        </div>
      </nav>
    </div>
  );
}

const BookNowButton = () => (
  <button type="button" className="btn btn-dark">
    <a
      href="https://book.nightsbridge.com/14037"
      target="_blank"
      rel="noreferrer"
      className="text-white"
    >
      Book Now
    </a>
  </button>
);

export default Navbar;
