import React from "react";
import "../../assets/styles/components/footer.css";
import logo from "../../assets/images/branding/k-logo-nt.png";

const ContactInfo = ({ iconClass, title, content, link }) => (
  <div className="icon d-flex justify-content-center align-items-center mb-3">
    {link ? (
      <a href={link} target="_blank" rel="noreferrer" className="d-flex align-items-center text-decoration-none">
        <div className="text">
          <div className="i me-2">
            <i className={iconClass}></i>
          </div>
          <h4>{title}</h4>
          <p>{content}</p>
        </div>
      </a>
    ) : (
      <div className="text">
        <div className="i me-2">
          <i className={iconClass}></i>
        </div>
        <h4>{title}</h4>
        <p>{content}</p>
      </div>
    )}
  </div>
);

const SocialLink = ({ href, iconClass }) => (
  <a href={href} target="_blank" rel="noreferrer" className="me-3">
    <i className={iconClass}></i>
  </a>
);

const Footer = () => (
  <footer>
    <div className="container text-center">
      <div className="row">
        <div className="col">
          <ContactInfo iconClass="bi bi-telephone" title="Phone" content="+27-21-552-4390" />
          <ContactInfo iconClass="bi bi-whatsapp" title="WhatsApp" content="+27-83-571-1688" link="https://wa.me/27835711688" />
          <ContactInfo iconClass="bi bi-envelope-at" title="Email" content="info@kwantuguesthouses.capetown" />
          <ContactInfo iconClass="bi bi-geo-alt" title="Location" content="67 Milnerton Drive, Milnerton Ridge, Cape Town" />
        </div>
        <div className="col">
          <div className="logo pb-3">
            <a href="#home">
              <img src={logo} alt="Kwantu Guesthouses Logo" />
            </a>
          </div>
          <div className="social">
            <h4>Follow Kwantu</h4>
            <SocialLink href="https://www.facebook.com/profile.php?id=61550234398860" iconClass="bi bi-facebook" />
            <SocialLink href="https://www.instagram.com/kwantu_guesthouses/?fbclid=IwAR2mbjsv-KYs1lO-ADbM2SehE2Hyg0UQNnyKo_AG9C0kKE-9rKaPMAGj3M8" iconClass="bi bi-instagram" />
          </div>
        </div>
        <div className="col">
          <div className="q-links pt-5">
            <p>
              Experience the ultimate in comfort and convenience at Kwantu Cape Town.
              Our guesthouses offer exceptional accommodation for business travelers
              and leisure guests alike. With easy access to the Central Business District,
              stunning beaches, renowned wine farms, Table Mountain, and the V&A Waterfront,
              Kwantu Cape Town is your perfect home away from home.
            </p>
          </div>
        </div>
      </div>
      <div className="row credit-con mt-4">
        <p className="credit">
          All Rights Reserved ||
          <span>
            <a href="#home" className="px-1 sitename">Kwantu Guesthouses</a>
          </span>
          <br />
          Website by
          <a
            href="https://walterhouse.co.za/"
            target="_blank"
            className="px-1 sitename"
            rel="noreferrer"
          >
            Walter House
          </a>
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
