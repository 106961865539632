import React, { memo, useMemo } from "react";
import "../../assets/styles/components/galleryComp.css";

const GalleryComp = ({ images }) => {
  const imageElements = useMemo(() => {
    return Object.keys(images).map((key, index) => (
      <div
        key={index}
        className={`col-md-${index % 3 === 0 ? "12" : "6"} mb-3`}
      >
        <img
          src={images[key]}
          alt={`Image ${index + 1}`}
          className="img-fluid w-100 rounded"
          loading="lazy"
        />
      </div>
    ));
  }, [images]);

  return (
    <div className="container">
      <div className="row">
        {imageElements}
      </div>
    </div>
  );
};

export default memo(GalleryComp);
