import React from "react";

function SocialMediaLinks() {
  return (
    <div className="d-flex order-2 order-md-1 mt-2 mt-md-0">
      <a
        href="https://www.facebook.com/profile.php?id=61550234398860"
        target="_blank"
        rel="noreferrer"
        className="px-2"
      >
        <i className="bi bi-facebook"></i>
      </a>
      <a
        href="https://www.instagram.com/kwantu_guesthouses/?fbclid=IwAR2mbjsv-KYs1lO-ADbM2SehE2Hyg0UQNnyKo_AG9C0kKE-9rKaPMAGj3M8"
        target="_blank"
        rel="noreferrer"
        className="px-2"
      >
        <i className="bi bi-instagram"></i>
      </a>
    </div>
  );
}

export default SocialMediaLinks;
