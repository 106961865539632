import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./pages/components/Navbar";
import Footer from "./pages/components/Footer";
import Contact from "./pages/components/Contact";
import Home from "./pages/Home";
import Attractions from "./pages/Attractions";
import Facilities from "./pages/Facilities";
import Gallery from "./pages/Gallery";
import { animateScroll as scroll } from "react-scroll";
import ScrollToTop from "./utils/scrollToTop";

function ScrollToSection({ section }) {
  const location = useLocation();

  useEffect(() => {
    if (section && location.pathname === "/") {
      const element = document.getElementById(section);
      if (element) {
        scroll.scrollTo(element.offsetTop, {
          duration: 500,
          smooth: "easeInOutQuad",
        });
      }
    }
  }, [location, section]);

  return null;
}

function App() {
  const [section, setSection] = useState(null);

  return (
    <>
      <Navbar setSection={setSection} />
      <ScrollToSection section={section} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Attractions" element={<Attractions />} />
        <Route path="/Facilities" element={<Facilities />} />
        <Route path="/Gallery" element={<Gallery />} />
      </Routes>
      <Contact />
      <Footer />
    </>
  );
}

export default App;
