import React from "react";

const AttractionsCard = ({ title, subtitle, list, links }) => {
  return (
    <div className="card">
      <div className="card-header">
        <h5>{title}</h5>
        <p>
          Whether you're looking to book a sightseeing adventure, spa treatment, game drive, or township tour, speak to our friendly and knowledgeable team who will be more than happy to assist in planning and booking your adventures.
        </p>
      </div>
      <div className="card-body">
        <h5 className="card-title">{subtitle}</h5>
        <div className="row">
          {list.map((column, index) => (
            <div className="col-md-4" key={index}>
              <ul className="card-text">
                {column.map((item, idx) => (
                  <li key={idx}>
                    <a href={links[index][idx]} target="_blank" rel="noopener noreferrer">{item}</a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <button
          type="button"
          className="btn btn-dark mt-3"
          data-bs-toggle="modal"
          data-bs-target="#contactModal"
        >
          Contact
        </button>
      </div>
    </div>
  );
};

export default AttractionsCard;
