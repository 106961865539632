import React from "react";
import "../../assets/styles/components/ContactInformation.css"; // Import the CSS file

function ContactInformation() {
  return (
    <div className="order-3 text-center text-md-end contact-info">
      <h6 className="badge text-bg-light d-flex align-items-center justify-content-center mt-2 mt-md-0 contact-item">
        <i className="bi bi-telephone-fill me-1"></i>
        <span>+27-21-552-4390</span>
      </h6>
      <a
        href="https://wa.me/27835711688"
        target="_blank"
        rel="noreferrer"
        className="d-flex align-items-center text-decoration-none"
      >
        <h6 className="badge text-bg-light d-flex align-items-center justify-content-center mt-2 mt-md-0 contact-item">
          <i className="bi bi-whatsapp me-1"></i>
          <span>+27-83-571-1688</span>
        </h6>
      </a>
    </div>
  );
}

export default ContactInformation;
