import React from "react";
import PropTypes from "prop-types";
import ImageGrid from "./components/ImageGrid";
import AttractionsCard from "./components/AttractionsCard";

const attractionsList = [
  [
    "Kirstenbosch National Botanical Garden",
    "V&A Waterfront",
    "Aquila Game Reserve",
    "Two Oceans Aquarium",
    "Zeitz Museum of Contemporary Art Africa",
    "Cape of Good Hope",
    "Atlantic Spa Treatment",
  ],
  [
    "Iziko Bo-Kaap Museum",
    "Lions Head",
    "Canal Walk Shopping Centre",
    "Table Mountain National Park",
    "District Six Museum",
    "Atlantis Dunes",
    "Oranjezicht City Farm Market",
  ],
  [
    "Sunset Cruises",
    "Blouberg Beach",
    "Muizenberg Beach",
    "DHL Stadium",
    "Robben Island",
    "Iziko South African Museum",
    "Franschhoek Wine Estates",
  ],
];

const attractionsLinks = [
  [
    "https://www.sanbi.org/gardens/kirstenbosch/",
    "https://www.waterfront.co.za/",
    "https://www.aquilasafari.com/",
    "https://www.aquarium.co.za/",
    "https://zeitzmocaa.museum/",
    "https://www.sanparks.org/parks/table_mountain/",
    "https://www.atlanticspatreatment.com/",
  ],
  [
    "https://www.iziko.org.za/museums/bo-kaap-museum",
    "https://www.capetown.travel/lions-head/",
    "https://canalwalk.co.za/",
    "https://www.sanparks.org/parks/table_mountain/",
    "https://www.districtsix.co.za/",
    "https://www.capetown.travel/atlantis-dunes/",
    "https://ozcf.co.za/",
  ],
  [
    "https://www.capetown.travel/sunset-cruises/",
    "https://www.capetown.travel/blouberg-beach/",
    "https://www.capetown.travel/muizenberg-beach/",
    "https://www.dhlstadium.co.za/",
    "https://www.robben-island.org.za/",
    "https://www.iziko.org.za/museums/south-african-museum",
    "https://franschhoek.org.za/wine-estates/",
  ],
];

const Attractions = React.memo(() => {
  return (
    <section className="container mt-5">
      <div className="heading text-center">
        <h5>So Much To Do</h5>
        <h1>Local Attractions</h1>
        <p>
          Kwantu Cape Town is surrounded by a wealth of local amenities and
          activities to keep you entertained. Discover nearby shopping centers,
          restaurants, and cafes offering a variety of cuisines. Our guesthouses
          are also close to cultural landmarks, museums, and entertainment
          venues, ensuring there's always something exciting to do.
        </p>
      </div>

      {/* Image Grid for Local Attractions */}
      <ImageGrid />

      <div className="text-center attractions mt-4">
        <AttractionsCard title="Kwantu's Top 21" subtitle="Cape Town Attractions" list={attractionsList} links={attractionsLinks} />
      </div>
    </section>
  );
});

Attractions.propTypes = {
  attractionsList: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.string.isRequired)
  ).isRequired,
};

export default Attractions;
