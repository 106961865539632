import React from "react";
import facilitiesData from "../assets/data/facilityData";
import "../assets/styles/pages/facility.css";

const Facility = ({ name, description, images }) => (
  <div className="facility">
    <div className="d-flex flex-row justify-content-center mb-3">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Facility ${index + 1}`}
          className="facility-image shadow img-fluid"
          loading="lazy"
        />
      ))}
    </div>
    {name && <h3 className="facility-name">{name}</h3>}
    <p className="facility-description">{description}</p>
  </div>
);

const Facilities = () => {
  const renderFacilities = (data, title, badgeText) => (
    <div className="mb-5">
      <h2>{title}</h2>
      <span className="badge bg-dark text-light text-wrap">{badgeText}</span>
      <div className="row justify-content-center mt-4">
        {data.map((facility, index) => (
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
            <Facility
              name={facility.name}
              description={facility.description}
              images={[facility.image1, facility.image2, facility.image3, facility.image4]}
            />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <section className="container" id="facilities">
      <div className="text-center mb-5">
        <div className="heading mb-4">
          <h1>Facilities & Services</h1>
          <h5>What's Available to You</h5>
        </div>
        <small className="text-body-secondary d-block mb-4">
          Host memorable events with our versatile spaces, exceptional catering,
          and modern conference center amenities.
        </small>
        {renderFacilities(
          facilitiesData.catering,
          "Catering",
          "Exquisite Menus | Exceptional Service | Customised Options"
        )}
        {renderFacilities(
          facilitiesData.functions,
          "Functions",
          "Versatile Event Spaces | Dedicated Event Coordinators | Memorable Experiences"
        )}
        {renderFacilities(
          facilitiesData.conference,
          "Conference Center",
          "Professional Support | Multiple Meeting Rooms | Modern Amenities"
        )}
        {renderFacilities(
          facilitiesData.shuttle,
          "Shuttle Service",
          "Comfortable | Reliable | Convenient"
        )}
      </div>
      <div className="card text-center mb-5 mx-auto" style={{ maxWidth: "600px" }}>
        <div className="card-header">Host With Kwantu Guesthouses</div>
        <div className="card-body">
          <h5 className="card-title">Book Your Event Today</h5>
          <i className="bi bi-calendar-check display-4 mb-3"></i>
          <p className="card-text">
            Experience the perfect blend of versatile event spaces, exquisite
            catering, and state-of-the-art conference facilities at our
            guesthouse. Whether you're planning a wedding, a corporate function,
            or a business meeting, our dedicated team is here to ensure your
            event is a resounding success. Don't wait—book your event with us
            today and create unforgettable memories!
          </p>
          <button
            type="button"
            className="btn btn-dark"
            data-bs-toggle="modal"
            data-bs-target="#contactModal"
          >
            Contact
          </button>
        </div>
      </div>
    </section>
  );
}

export default Facilities;
