import React from 'react';
import GalleryComp from "./components/GalleryComp";
import logo from "../assets/images/branding/k-logo-nt.png";

// Function to import all images from a folder
function importAll(r) {
  return r.keys().map(r);
}

const guesthouseImages = {
  k1: importAll(require.context("../assets/images/guesthouses/k1", false, /\.(png|jpe?g|svg)$/)),
  k2: importAll(require.context("../assets/images/guesthouses/k2", false, /\.(png|jpe?g|svg)$/)),
  k3: importAll(require.context("../assets/images/guesthouses/k3", false, /\.(png|jpe?g|svg)$/)),
};

const Card = ({ iconClass, text, buttonText, buttonLink, buttonTarget, buttonDataBsToggle, buttonDataBsTarget }) => (
  <div className="card align-items-center align-items-start flex-column mb-3">
    <i className={iconClass}></i>
    <div className="card-body text-center">
      <p className="card-text">{text}</p>
      <button
        type="button"
        className="btn btn-dark"
        data-bs-toggle={buttonDataBsToggle}
        data-bs-target={buttonDataBsTarget}
      >
        {buttonLink ? (
          <a href={buttonLink} target={buttonTarget}>
            <span>{buttonText}</span>
          </a>
        ) : (
          buttonText
        )}
      </button>
    </div>
  </div>
);

const GuesthouseSection = ({ title, images }) => (
  <>
    <h2>{title}</h2>
    <GalleryComp images={images} />
  </>
);

function Gallery() {
  return (
    <section className="container text-center">
      <div className="heading">
        <h5>Explore Kwantu</h5>
        <h1>Gallery</h1>
      </div>
      {Object.entries(guesthouseImages).map(([key, images]) => (
        <GuesthouseSection key={key} title={`Kwantu ${key.charAt(1)}`} images={images} />
      ))}
      <div className="card align-items-center">
        <i className="bi bi-patch-check"></i>
        <div className="card-body">
          <p className="card-text">For Your Enjoyable Stay</p>
          <h3 className="card-title">Kwantu Guesthouses</h3>
        </div>
      </div>
      <div className="d-flex py-2">
        <div className="col">
          <Card
            iconClass="bi bi-calendar-check"
            text="Book Your Stay"
            buttonText="Book Now"
            buttonLink="https://book.nightsbridge.com/14037"
            buttonTarget="_blank"
          />
        </div>
        <div className="col d-flex justify-content-center align-items-center">
          <img src={logo} className="img-fluid" alt="" />
        </div>
        <div className="col">
          <Card
            iconClass="bi bi-telephone"
            text="Contact Kwantu"
            buttonText="Contact Us"
            buttonDataBsToggle="modal"
            buttonDataBsTarget="#contactModal"
          />
        </div>
      </div>
    </section>
  );
}

export default Gallery;
